import styled from 'styled-components';

import { YEAR } from 'modules/common/constants';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  justify-content: center;
  padding: 32px 0;
  width: 100%;
`;

export const Footer = () => {
  return (
    <Wrapper>
      <div>© {YEAR} Todos os direitos reservados</div>
      <a href="https://olist.com.br" target="_blank" rel="noreferrer">
        olist.com.br
      </a>
    </Wrapper>
  );
};
