import styled from 'styled-components';

import { Icon } from '@olist/united';

const Alert = styled.div`
  align-items: center;
  background: #fff9c4;
  color: #424242;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0px
  min-height: 40px;
  padding: 8px 16px;
  position: fixed;
  width: 100%;
  z-index: 1101;
  font-size: 0.875rem;
  box-sizing: border-box;
  top: 0;
`;

export const StagingAlert = () => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'staging') {
    return null;
  }

  return (
    <Alert>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <Icon
            name="alert-circle-fill"
            size={20}
            color="#ff9800"
            style={{ marginRight: 4, flexShrink: 'none' }}
          />
        </div>
        <div>
          Agora você usa uma conta única para acessar o ambiente de testes dos e-commerces Vnda que
          tiver permissão,&nbsp;
          <a
            style={{ display: 'inline' }}
            target="_blank"
            rel="noreferrer"
            href="http://ajuda.vnda.com.br/pt-BR/articles/3760960-ambiente-de-testes-staging"
          >
            saiba mais aqui
          </a>
        </div>
      </div>
    </Alert>
  );
};
