import styled from 'styled-components';

import { Text } from '@olist/united';

import { HELP_ARTICLE_URL } from 'modules/common/constants';
import { Link } from 'modules/components/Link';

const Wrapper = styled.div`
  align-items: center;
  color: rgb(80, 85, 124);
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  text-align: center;
`;

export const Information = () => {
  return (
    <Wrapper>
      <Text>Agora você usa uma conta única para acessar</Text>
      <Text>o painel administrativo dos e-commerces da Olist Vnda que tiver permissão.</Text>
      <Text>
        Caso tenha alguma dúvida,{' '}
        <Link href={HELP_ARTICLE_URL} target="_blank" rel="noreferrer" anchor>
          saiba mais aqui
        </Link>
        .
      </Text>
    </Wrapper>
  );
};
